import React from 'react'

import TextField from '@material-ui/core/TextField'

import './StyledTextField.scss'

const StyledTextField = ({
  error,
  id,
  defaultValue,
  helperText,
  label,
  ...props
}) => (
  <TextField
    error={error}
    id={id}
    label={label}
    defaultValue={defaultValue}
    helperText={helperText}
    {...props}
  />
)

export default StyledTextField
