import React from 'react'

import * as styles from './styles.module.scss'

const Checkbox = ({ className, value, onChange }) => (
  <div className={`${styles.checkbox} ${className}`}>
    <input
      aria-label="getNda"
      name="getNda"
      id="getNda"
      type="checkbox"
      checked={value}
      onChange={onChange}
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      onClick={onChange}
    >
      <path
        d="M13.5629 1.16934L13.5627 1.16955L6.04963 8.8846L2.43716 5.1953C2.43715 5.1953 2.43715 5.1953 2.43715 5.1953C2.08552 4.83616 1.51456 4.83621 1.16288 5.19529L1.16288 5.1953C0.812371 5.55323 0.812376 6.13267 1.16288 6.49063L5.41267 10.8307C5.41268 10.8308 5.41268 10.8308 5.41269 10.8308C5.76424 11.1899 6.33558 11.1896 6.68696 10.8308L6.68714 10.8306L14.8371 2.46471C15.1876 2.10678 15.1876 1.5273 14.8371 1.16935C14.4855 0.810212 13.9145 0.810222 13.5629 1.16934Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
        onClick={onChange}
      />
    </svg>
    <label>
      <span>Get NDA</span>
    </label>
  </div>
)

export default Checkbox
