// extracted by mini-css-extract-plugin
export var formBlock = "styles-module--form-block--ysMOy";
export var formBlockContainer = "styles-module--form-block-container--s17Wt";
export var formBlockText = "styles-module--form-block-text--UBkdV";
export var formBlockTabs = "styles-module--form-block-tabs--GSm9D";
export var formBlockBtns = "styles-module--form-block-btns--t1w3r";
export var formBlockBtnsActive = "styles-module--form-block-btns-active--GYys4";
export var formBlockFormHi = "styles-module--form-block-form-hi---iUql";
export var formBlockFormHiName = "styles-module--form-block-form-hi-name----2yr";
export var formBlockFormHiEmail = "styles-module--form-block-form-hi-email--ueyl+";
export var formBlockFormHiCheckbox = "styles-module--form-block-form-hi-checkbox--ZwDOD";
export var formBlockFormHiProject = "styles-module--form-block-form-hi-project--nelqk";
export var formBlockFormHiBtn = "styles-module--form-block-form-hi-btn--QisX5";
export var formBlockFormBlock = "styles-module--form-block-form-block--k6gT7";
export var formBlockFormBlockWrapper = "styles-module--form-block-form-block-wrapper--okKdw";
export var formBlockFormBlockTitle = "styles-module--form-block-form-block-title--zkUmJ";
export var formBlockFormBlockDiv = "styles-module--form-block-form-block-div--fZXeI";
export var formBlockFormBlockDivActive = "styles-module--form-block-form-block-div-active--e4n1m";
export var formBlockFormBlockBudget = "styles-module--form-block-form-block-budget--RjmQr";