import React, { Component } from 'react'
import { navigate } from 'gatsby'

import StyledTextField from '../../sharedComponents/StyledTextField/StyledTextField'
import Checkbox from '../../sharedComponents/Checkbox/Checkbox'
import { WhiteLink } from '../../sharedComponents/Links/Links'
import { MainButton } from '../../sharedComponents/buttons/Buttons'
import InfoChip from '../../../assets/styles/customChips'

import * as styles from './styles.module.scss'

class WorkWithUsForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()
    this.state = {
      disabledButton: true,
      value: {
        email: '',
        name: '',
        project: '',
        getNda: false,
        needSelectedArr: [],
        budgetSelectedArr: [],
      },
      valid: {
        email: false,
        name: false,
        project: false,
      },
    }
    this.needArr = [
      'Branding',
      'Web design',
      'HTML/CSS coding',
      'App design',
      'IOS Development',
      'Site from scrath',
      'Other',
    ]
    this.budgetArr = [
      'Less than $5k',
      '$5-10k',
      '$10-30k',
      'more than $30k',
      'Help me estimate',
    ]
    this.width = typeof window !== 'undefined' && window.innerWidth
  }

  encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleChange(value, name) {
    this.state.disabledButton && this.setState({ disabledButton: false })
    const approvedFieldStatus = (pattern, keys) => {
      this.setState((prevState) => ({
        ...prevState,
        value: { ...prevState.value, [keys]: value },
      }))
      if (pattern)
        this.setState((prevState) => ({
          ...prevState,
          valid: { ...prevState.valid, [keys]: false },
        }))
      else {
        this.setState((prevState) => ({
          ...prevState,
          valid: { ...prevState.valid, [keys]: true },
        }))
      }
    }
    switch (name) {
      case 'email':
        approvedFieldStatus(value.match(/^.+@.+\..+$/gim), 'email')
        return null
      case 'name':
        approvedFieldStatus(value.trim().length > 1, 'name')
        return null
      case 'project':
        approvedFieldStatus(value.trim().length > 1, 'project')
        return null
      case 'checkbox':
        approvedFieldStatus(value, 'getNda')
        return null
      default:
        return null
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { valid, value, disabledButton } = this.state
    const isFormValid = !(
      disabledButton ||
      valid.email ||
      valid.name ||
      valid.project ||
      value.email === '' ||
      value.name === '' ||
      value.project === ''
    )
    if (isFormValid) {
      let formData = {
        ...value,
        needs: value.needSelectedArr?.join(),
        budget: value.budgetSelectedArr,
        getNda: String(value.getNda),
      }
      this.setState({ disabledButton: true }, () => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': 'contact-say-hi-with',
            ...formData,
          }),
        })
          .then(() => {
            this.setState({ disabledButton: false })
            navigate('/success/')
          })
          .catch((error) => {
            console.log('====================================')
            console.log(`error in submiting the form data:${error}`)
            console.log('====================================')
          })
      })
    } else {
      this.setState({
        valid: {
          email: value.email === '',
          name: value.name === '',
          project: value.project === '',
        },
      })
    }
  }

  addToNeedSelectedArr(item, arr) {
    const { value } = this.state
    let index = value[arr].findIndex((elem) => item === elem)
    if (index !== -1) {
      this.setState({
        value: {
          ...value,
          [arr]: value[arr].filter((item, ind) => index !== ind),
        },
      })
    } else {
      this.setState({
        value: {
          ...value,
          [arr]: [...value[arr], item],
        },
      })
    }
  }

  addToNeedSelectedArrBudget = (item, key) => {
    const { value } = this.state
     this.setState({
        value: {
          ...value,
          [key]: item,
        },
      })
  }

  render() {
    const { valid, value } = this.state
    const { isShowSayHi } = this.props
    return (
      <form
        name="contact-say-hi-with"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref={this.ContactForm}
        className={styles.formBlockFormHi}
        onSubmit={this.handleSubmit}
      >
        {!isShowSayHi && (
          <div className={styles.formBlockFormBlock}>
            <p className={styles.formBlockFormBlockTitle}>I need</p>
            <div className={styles.formBlockFormBlockWrapper}>
              {this.needArr.map((item) => {
                return (
                  <div
                    key={item}
                    onClick={(e) => {
                      e.target.classList.toggle(
                        styles.formBlockFormBlockDivActive
                      )
                      this.addToNeedSelectedArr(item, 'needSelectedArr')
                    }}
                    className={styles.formBlockFormBlockDiv}
                  >
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <StyledTextField
          aria-label="name"
          type="text"
          name="name"
          id="name"
          value={value.name}
          onChange={(e) => this.handleChange(e.target.value, 'name')}
          className={styles.formBlockFormHiName}
          label={this.width > 768 ? 'Your name' : 'Name'}
          error={valid.name}
        />
        <StyledTextField
          aria-label="email"
          type="email"
          name="email"
          id="email"
          value={value.email}
          onChange={(e) => this.handleChange(e.target.value, 'email')}
          className={styles.formBlockFormHiEmail}
          label={this.width > 768 ? 'Your Email' : 'Email'}
          error={valid.email}
        />
        <StyledTextField
          className={styles.formBlockFormHiProject}
          label={
            this.width > 768
              ? 'Your message or question for us'
              : 'Message or question'
          }
          error={valid.project}
          aria-label="project"
          type="text"
          name="project"
          id="project"
          value={value.project}
          onChange={(e) => this.handleChange(e.target.value, 'project')}
        />
        <input
          type="hidden"
          aria-label="needs"
          name="needs"
          id="needs"
          value={value?.needSelectedArr?.join()}
        />
        <input
          type="hidden"
          aria-label="budget"
          name="budget"
          id="budget"
          value={value.budgetSelectedArr}
        />
        {!isShowSayHi && (
          <div
            className={`${styles.formBlockFormBlock} ${styles.formBlockFormBlockBudget}`}
          >
            <p className={styles.formBlockFormBlockTitle}>Budget</p>
            <div className={styles.formBlockFormBlockWrapper}>
              {this.budgetArr.map((item) => {
                return (
                    <InfoChip
                      key={item}
                      label={item}
                      clickable
                      onClick={() => {
                        this.addToNeedSelectedArrBudget(item, 'budgetSelectedArr')
                      }}
                      type='select'
                    />
                )
              })}
            </div>
          </div>
        )}
        <Checkbox
          value={value.getNda}
          onChange={() => this.handleChange(!value.getNda, 'checkbox')}
          className={styles.formBlockFormHiCheckbox}
        />
        <p>
          I consent to Justice team processing my personal data according to the{' '}
          <WhiteLink to="/terms-and-conditions/privacy-policy/">
            Privacy Policy
          </WhiteLink>
        </p>
        <MainButton className={styles.formBlockFormHiBtn}>
          Send request
        </MainButton>
      </form>
    )
   }
}

export default WorkWithUsForm
