import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const InfoChip = withStyles({

  root: {
    height:'fit-content',
    backgroundColor: 'inherit',
    paddingLeft:'20px',
    paddingRight:'20px',
    paddingTop:'16px',
    paddingBottom:'16px',
    marginRight:'16px',
    marginBottom:'16px',
    color: 'rgba(255, 255, 255, 0.7)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '18px',
    lineHeight:'16px',

    '& .MuiTouchRipple-root' : {
      display:'none',
      backgroundColor:'red'
    },
  },

  clickable: {
    "&:hover, $deletable&:hover": {
      background: 'rgba(255, 255, 255, 0.2)'
    },

    "&:focus, $deletable&:focus": {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      color: 'black'
    }},

  avatar: {
    color: "inherit",
    backgroundColor: "rgba(255,255,255,0)",
  },

})(Chip);

export default InfoChip;
