import React, { useState } from 'react'

import { BlueLink, WhiteLinks } from '../../sharedComponents/Links/Links'
import WorkWithUsForm from './WorkWithUsForm'

import * as styles from './styles.module.scss'

const FormBlock = () => {
  const [isShowSayHi, setIsShowSayHi] = useState(true)

  return (
    <div id="contact-us" className={styles.formBlock}>
      <div className={`${styles.formBlockContainer} container`}>
        <div className={styles.formBlockText}>
          <h2>Let`s Discuss Your Ideas</h2>
          <p>Simply fill out the form or email us at </p>
          <BlueLink href="mailto:hello@it-justice.com">
            hello@it-justice.com
          </BlueLink>
        </div>

        <div className={styles.formBlockTabs}>
          <div className={styles.formBlockBtns}>
            <WhiteLinks
              className={isShowSayHi ? styles.formBlockBtnsActive : null}
              onClick={() => setIsShowSayHi(true)}
            >
              Say Hi
            </WhiteLinks>
            <WhiteLinks
              className={!isShowSayHi ? styles.formBlockBtnsActive : null}
              onClick={() => setIsShowSayHi(false)}
            >
              Work with us
            </WhiteLinks>
          </div>
          <WorkWithUsForm isShowSayHi={isShowSayHi} />
        </div>
      </div>
    </div>
  )
}

export default FormBlock
